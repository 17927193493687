import React, { useState } from "react"
import Img from "gatsby-image/withIEPolyfill"

import Video from "../video"

const Landing = ({ content }) => {

    let [useCases, setUseCases] = useState(content.useCasesList.map(usecase => { let o = { ...usecase, isActive: false }; return o }));

    const toggleUseCase = (activeUseCaseId) => {
        let newUseCasesArray = useCases.map(usecase => {
            if (usecase.id === activeUseCaseId) {
                usecase.isActive = !usecase.isActive;
            }

            return usecase
        })

        setUseCases(newUseCasesArray);
    }

    return (
        <section className="common-wrapper mt-10 px-15 px-4-md">
            <div className="flex flex-col-md justify-evenly pt-5">
                <div className={content.clientsLogo ? "text-align-left w-half w-full-md" : "text-align-left w-full"}>
                    <h1 className="f1 text-blue-dark mt-0">{content.title}</h1>
                    {content.description && <div className="block f4 font-normal lh-4 text-blue-dark common-list" dangerouslySetInnerHTML={{ __html: content.description.childMarkdownRemark.html }}></div>}
                </div>
                {/* <div className="px-10 w-half w-full-md px-0-md relative">
                    <div className="pt-3 t-0 sticky">
                        <h2 className="f3 text-blue-dark font-bold mt-0">Présentation du pôle</h2>
                        <Video video={content.poleDescription} />
                    </div>
                </div> */}
                { content.clientsLogo && 
                <div className="text-align-center pt-4 mt-6-md w-half w-full-md">
                    <p className="text-blue-dark font-bold f4 mt-0">Ils nous font confiance</p>
                    <ul className="flex flex-col flex-row-md flex-wrap-xs justify-evenly items-center mt-5 relative">
                        {content.clientsLogo.map(clientLogo => {
                            return (
                                <li className="w-25 h-25 my-5 shadow-1 br-2 p-4 bg-white" key={clientLogo.id}>
                                    {clientLogo && clientLogo.fluid && <Img className="transform-center" fluid={clientLogo ? clientLogo.fluid : ''} alt={clientLogo ? clientLogo.description : ''} />}
                                </li>
                            )
                        })}
                    </ul>
                    <a href="/track-records/"><p className="text-blue-dark font-bold f6 mt-5 underline">Voir plus</p></a>
                </div>}
            </div>
            { content.featuredSkills && 
            <div className="flex justify-between text-align-left py-15 py-10-md py-5-xs">
                <ul className="mt-5 relative flex flex-col-xs justify-evenly w-full">
                    {content.featuredSkills.map(skill => {
                        return (
                            <li className="w-full-xs w-33-per flex-grow-1 flex justify-center items-center inline-block vertical-align-middle mr-2 my-2 br-2 p-6 bg-grey-4 text-align-center bs-solid bc-blue-dark bw-2">
                                <span className="text-blue-dark f4 font-normal">{skill}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>}
            { useCases &&
            <div className="flex justify-between text-align-left py-15 py-10-md py-5-xs">
                <div>
                    <h2 className="f2 text-blue-dark font-bold">{content.useCaseSectionTitle}</h2>
                    <ol className="common-list m-0 px-0">
                        {useCases.map((usecase, id) => {
                            return (
                                <li key={usecase.id} className="common-list-item flex flex-col-md my-10 justify-between relative">
                                    <div className={usecase.isActive ? "bg-grey-4 pt-9 px-8 mr-3 mr-0-md relative use-case-dropdown active w-full" : "bg-grey-4 pt-9 px-8 mr-3 relative use-case-dropdown w-full"}>
                                        <h3 className="f3 text-blue-dark mt-0 mt-8-md">{usecase.title}</h3>
                                        { usecase.description && <div className="block f4 font-normal lh-4 text-blue-dark" dangerouslySetInnerHTML={{ __html: usecase.description.childMarkdownRemark.html }}></div>}
                                        <span onClick={() => toggleUseCase(usecase.id)} className="cursor-pointer absolute t-9 r-10 flex font-bold justify-center items-center underline">
                                            {usecase.isActive ? 'Lire moins' : 'Lire plus'}
                                        </span>
                                    </div>
                                    {content.keyFigures && content.keyFigures[id] &&
                                        (<div className="mt-4-md shadow-1 br-2 p-4 bg-white relative bg-blue-dark min-width-100px flex justify-center align-center">
                                            <span className="f4 text-white font-bold text-align-center">{content.keyFigures[id]}</span>
                                        </div>)}
                                </li>
                            )
                        })}
                    </ol>
                </div>
            </div>}
        </section>
    )
}

export default Landing